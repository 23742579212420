.profile {
    width: 100px;
    height: 100px;
    border: 4px solid rgb(255, 255, 255);
    border-radius: 50%;
}

.Avatar {
    border-radius: 50%;
    cursor: pointer;
}

.profileSm {
    width: 35px;
    height: 35px;
    border: 4px solid rgb(255, 255, 255);
    border-radius: 50%;

}

.noBorder:focus {
    outline: none;
    /* Removes the default outline */
    box-shadow: none;
    /* Removes any box-shadow */
}

input::placeholder {
    padding-left: 20px !important;
}

.arrow {
    width: 30px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 50%;
    box-sizing: border-box;
    /* Include border inside the element's dimensions */
}

.activePage {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    background-color: #691DF8;
    border: 3px solid white;
    /* White border */
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    border-radius: 50%;
    /* Keep the element round */
}