/* @import url('https://fonts.googleapis.com/css2?family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap'); */
@import url('https://fonts.googleapis.com/css2?family=Inter:ital,opsz,wght@0,14..32,100..900;1,14..32,100..900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

:root {
  --first-color: #27374D;
  --sec-color: #526D82;
  --thrd-color: #9DB2BF;
  --fourth-color: #DDE6ED;
  --text-color: rgb(60, 60, 218);
  --gray-color: #EEEEEE;
  --babyRed-color: #ff4b5c;
  --input-color: #EBE6F0;
  --Documents-color: #FEF7FF;
  --bink-color: #E8DEF8;
  --paragrh-color: #49454F;
  --paragrh-Active-color: #1D1B20;
  --search-color: #ABB5BE;
  --primary-color: #7749F8;
}

.prevent-select {
  -webkit-user-select: none;
  /* Safari */
  -ms-user-select: none;
  /* IE 10 and IE 11 */
  user-select: none;
  /* Standard syntax */
}

body {
  font-family: "Inter", sans-serif;
  font-optical-sizing: auto;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #F9F3FA;
}


code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


.text-frist {
  color: var(--first-color);
}

.bg-first {
  background-color: var(--first-color);
}

.text-sec {
  color: var(--sec-color);
}

.bg-sec {
  background-color: var(--sec-color);
}

.text-thrd {
  color: var(--thrd-color);
}

.bg-thrd {
  background-color: var(--thrd-color);
}

.text-fourth {
  color: var(--fourth-color);
}

.bg-fourth {
  background-color: var(--fourth-color);
}

.colorTest {
  background: #F2F2F2;
}

#loginButton:hover {
  background: #27374dd3 !important;
}

.borderForm {
  border: 1px solid darkblue;
  border-radius: 20px;
}

.text-color {
  color: var(--text-color);
}

.bg-gray {
  background-color: #EEEEEE !important;
}

.bg-red {
  background-color: var(--babyRed-color)
}

.text-red {
  color: var(--babyRed-color)
}

.holdCursor {
  cursor: pointer;
}


#Content :hover .policeName {
  color: rgb(55, 55, 229) !important
}

.inputColor {
  background-color: var(--input-color);
}

.documents {
  background-color: var(--Documents-color) !important;


}

.rounded-table {
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #D8D6D6;
  border-radius: 6px;
  overflow: hidden;
}

.rounded-table thead th,
.rounded-table tbody td {
  border: 1px solid #D8D6D6;
}

.rounded-table thead th:first-child {
  border-top-left-radius: 6px;
}

.rounded-table thead th:last-child {
  border-top-right-radius: 6px;
}

.rounded-table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 6px;
}

.rounded-table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 6px;
}

.rounded-table thead th,
.rounded-table tbody td {
  white-space: nowrap;
}


.nav-color {
  background-color: var(--bink-color);
  width: 64px;
  height: 32px;
  border-radius: 16px;
  text-align: center;
}

.activeP {
  color: var(--paragrh-Active-color);
  font-size: medium;
  font-weight: 600 !important;
}

.inactiveP {
  color: var(--paragrh-color);
  font-size: medium;
  font-weight: 500 !important;

}

.slide-toggle {
  overflow: hidden;
  transition: max-height 0.8s ease-in-out, opacity 1.5s ease-in-out;
  right: 15px;
}

.closed {
  max-height: 0;
  opacity: 0;
}

.open {
  max-height: 200px;

}

.pSlected {
  color: var(--paragrh-color);
}

.pSlected:hover {
  background-color: var(--bink-color);
}

.search {
  background-color: var(--search-color);
  border: 1px solid #ECE6F0;
  border-radius: 4px;
  padding: 3px 4px !important;
  margin-left: 15px;

}

.close-icon {
  color: #747474;
  width: 16px;
  height: 16px;
}

.dropdown-menu {
  max-height: 200px;
  /* Adjust height as needed */
  overflow-y: auto;
  /* Add scrollbar if needed */
  background-color: white;
  /* Background color */
  border: 1px solid #ddd;
  /* Border color */
  border-radius: 0.25rem;
  /* Rounded corners */
}

/* words-text  */
.title {
  font-weight: 300;
  font-size: 2rem;
  text-align: left;
}

.p-text {
  margin-top: 30px !important;
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 30px;
}

.HeadtextStyle {
  font-weight: 300;
  font-size: 1.5rem;
  line-height: 30px;
}

/* search bar */
.search-container {
  width: 100%;
  max-width: 600px;
}

.search-bar {
  border-radius: 30px;
  overflow: hidden;
  background-color: #f1edf4;
  /* Update to match the soft background color */
  box-shadow: none;
  /* Remove shadow if it doesn't match the desired design */
  position: relative;
  /* Ensure relative positioning for the icon */
}

.search-bar .form-control {
  border: none;
  border-radius: 30px;
  /* Match the rounded corners */
  background-color: var(--input-color);
  /* Ensure transparency to show the background color */
  padding-right: 40px;
  /* Space for the icon */
  color: #6c757d;
  /* Adjust text color to match the hint text */
}

.search-bar .form-control::placeholder {
  color: #6c757d;
  /* Hint text color */
  opacity: 1;
  /* Ensure the placeholder text is fully visible */
}

.search-bar .input-group-text {
  background-color: transparent;
  border: none;
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  /* Ensure the icon is non-interactive */
  color: #000;
  /* Adjust icon color to match */
}

.search-bar .fa-magnifying-glass {
  color: #000;
  /* Match icon color */
}

.search-bar .form-control:focus {
  box-shadow: none;
  /* Remove default focus shadow */
  outline: none;
  /* Remove default focus outline */
}

.search-results {
  width: 100%;
  max-width: 600px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  background-color: white;
  border-radius: 8px;
  z-index: 10;
}

.list-group-item {
  padding: 10px 15px;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  background-color: var(--Documents-color) !important;

}

.list-group-item:hover {
  background-color: #f2e9fad6 !important;
}

.searchText {
  color: #070707;
  font-weight: 400;
  font-size: 0.813rem;
  line-height: 20px;
}

/* checkbox */
/* Hide the native checkbox */
/* Custom checkbox/radio styling for both single and multi-select */
/* Hide the native input */
.custom-input {
  position: absolute;
  opacity: 0;
  /* Hide the native input */
  pointer-events: none;
  /* Prevent interaction */
}

/* Create the custom checkmark */
.custom-checkmark {
  display: inline-block;
  width: 16px;
  height: 16px;
  border: 1px solid var(--search-color);
  /* Border color */
  margin-right: 8px;
  position: relative;
  transition: border-color 0.3s, background-color 0.3s;
  cursor: pointer;
  border-radius: 4px;
  /* Default rounded corners for checkboxes */
}

/* Apply border-radius 50% only for radio buttons */
.custom-input[type="radio"]:not(:checked)+.custom-checkmark,
.custom-input[type="radio"]:checked+.custom-checkmark {
  border-radius: 50%;
  /* Border-radius for radio buttons */
}

/* Label styling */
.custom-input-label {
  color: #343a40;
  /* Label text color */
}

/* For radio/checkbox when selected */
.custom-input:checked+.custom-checkmark {
  border-color: #7749f8;
  /* Border color when checked */
  background-color: #7749f8;
  /* Background color when checked */
}

/* Inner indicator for checked radio */
.custom-input[type="radio"]:checked+.custom-checkmark:before {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 6px;
  height: 6px;
  background-color: #ffffff;
  /* Inner circle color */
  border-radius: 50%;
}

/* For checked checkboxes */
.custom-input[type="checkbox"]:checked+.custom-checkmark {
  background-color: #7749f8;
}

.custom-input[type="checkbox"]:checked+.custom-checkmark:before {
  content: "✔";
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 0.75rem;
}

/* end */
.bg-light-black {
  background: #00000056;
}

.text-Babyblue {
  color: #0176D3
}


/* CustomAlert.css */

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 1000;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  opacity: 0;
  /* Start invisible */
  transition: opacity 0.3s ease-in-out;
  /* Transition for fade */
}

.modal.fade-in {
  opacity: 1;
  /* Fade in */
}

.modal.fade-out {
  opacity: 0;
  /* Fade out */
}

.modal-content {
  background-color: #fefefe;
  padding: 20px;
  border: 1px solid #888;
  border-radius: 8px;
  text-align: center;
  transform: translateY(-50px);
  /* Start from above */
  transition: transform 0.3s ease-in-out;
  /* Transition for sliding */
}

.modal.fade-in .modal-content {
  transform: translateY(0);
  /* Move into position */
}

.modal.fade-out .modal-content {
  transform: translateY(-50px);
  /* Move up while fading out */
}

.close-button {
  color: #aaa;
  float: right;
  font-size: 1.75rem;
  font-weight: bold;
}

.close-button:hover,
.close-button:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

button {
  padding: 10px 20px;
  border: none;
  background-color: #4CAF50;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

button:hover {
  background-color: #45a049;
}

.model-bg {
  background-color: hsla(180, 2%, 25%, 0.337);
}

/* Model */
/* CSS */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: start;
  z-index: 1000;
}

.model-prop {
  background: #222529;
  color: #DEE2E6;
  border-radius: 8px;
  padding: 20px;
  max-width: 400px;
  width: 100%;
  transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;
  transform: translateY(-300px);
  /* Start position */
  opacity: 0;
  /* Start fully transparent */
}

.model-prop.active {
  transform: translateY(20px);
  /* Final position */
  opacity: 1;
  /* Fade to full opacity */
}

.model-heading,
.model-body,
.model-footer {
  padding: 10px;
  border-bottom: 1px solid #495057;
}

.model-footer {
  text-align: right;
  border-bottom: none;
}

/* end */
.upload-btn-wrapper {
  color: #3e62d1;
  /* Gray text color */
  text-decoration: underline !important;
  /* Remove underline */
  font-weight: 400;
  /* Optional: Adjust font weight */
  font-style: normal;
  /* Optional: Adjust font style */
}

/* marketing */
#FormId #fieldId::placeholder {
  /* color: ; */
  padding: 0px !important;
}

#FormId #labelId {
  /* color: ; */
  padding: 0px 5px !important;
}

#FormId .form-control {
  padding: 6px 5px !important;
}

.activeColor {
  color: #870eaf;
}

/* tabs */
.nav-link.active {
  border-bottom-color: #FEF7FF !important;
  font-weight: bold;
  background-color: #FEF7FF !important;
}

.tabsContainer {
  display: flex;
  justify-content: space-around;
  padding: 0;
  margin-top: 1rem;
  border-bottom: 2px solid #e0e0e0;
  list-style: none;
}

.tabItem {
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  transition: color 0.3s ease, border-bottom 0.3s ease;
  font-weight: 500;
  color: #666;
  border-bottom: 3px solid transparent;
}

.tabItem:hover {
  color: #333;
}

/* custom button */
/* Primary Gradient Button */
.btnCustom {

  color: #fff !important;
  background: linear-gradient(90deg, #d700ff, #9c00ff) !important;

  transition: all 0.3s ease-in-out;
}

/* Primary Hover */
.btnCustom:hover {
  filter: brightness(1.1);
  box-shadow: 0 0 10px rgba(215, 0, 255, 0.5);
}

/* form control */
#newField::placeholder,
#documentName::placeholder {
  margin: 0px !important;
  padding: 0px !important;
}