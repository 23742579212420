.size {
    width: 100%;
    height: 100%;

}

.container {
    background-color: '#F9F3FA';
    padding-top: 0;
}

.documentHeader {
    border: 1px solid rgb(216, 214, 214);
    border-radius: "25px";
    padding-bottom: "1rem";
    padding-top: "1rem";
}

.headerText {
    font-weight: "600";

}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
    .size {
        height: 100vh;
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 767px) {
    .MobileView {
        margin-top: 10px !important;
    }
}