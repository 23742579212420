/* .inputStyle {

    border-bottom: 1px solid #000;
    border-top: none;
    border-right: none;
    border-left: none;
    display: block;
} */

#loginButton:hover {
    background: #27374d3f !important;
}

.logo {
    width: 120px;
    position: relative;
    /* Ensure positioning context for pseudo-elements */
}

.otpInput {
    width: 50px;
    height: 50px;
    text-align: center;
    border: 1px solid #ccc;
    border-radius: 10px;
}

.otpInput::placeholder {
    padding: 0 !important;
    margin: 0 !important;
}

.loginLine {
    position: relative;
    display: block;
    color: #ccc;
    font-size: 0.938rem;
}

.loginLine::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    width: 22%;
    height: 0.5px;
    background-color: #ccc;
}

.loginLine::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    transform: translateY(-50%);
    width: 22%;
    height: 0.5px;
    background-color: #ccc;
}

/* .inputStyle::placeholder */

.placeholderPosition::placeholder {
    padding: 0 !important;
    margin: 0 !important;
}
.btnColor{
    background-color: #2268DE !important;
    color: #fff !important;
}