li {
    color: #343A40;
    font-weight: 400;
    line-height: 20px;
    font-size: 1rem;
}

.btnFiles {
    Width: 113px;
    Height: 32px;
    border-radius: 6px;
    padding-top: 8px;
    padding-right: 12px;
    padding-bottom: 8px;
    padding-left: 12px;
    Opacity: 55%;
    background-color: #6C757D;
    color: #fff;
    line-height: 16px;
    font-size: 0.875rem;
    font-weight: 600;
}

.btnFiles:hover {
    background-color: #555d65;
    color: #fff;
}


.nextSectionbtn {
    background: var(--primary-color);
    padding: 8px, 12px, 8px, 12px;
    border-radius: 6px;
}

.nextSectionbtn:hover {
    background: #653ed1;
}

.trash:hover {
    color: red;
    animation: trashAnimation 0.5s ease-in-out;
}

.inputText {
    background: #E0E0E0;
    padding-bottom: 90px;
    padding-left: 0px;
}

.inputText::placeholder {
    margin: 0px !important;
    padding: 0px !important;

}

@keyframes trashAnimation {

    0%,
    100% {
        transform: translateX(0);
    }

    25% {
        transform: translateX(1.5px);
    }

    50% {
        transform: translateX(-1.5px);
    }

    75% {
        transform: translateX(1.5px);
    }
}