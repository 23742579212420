.indicatorContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;


}

.row {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  width: 100%;
  height: 100%;

}

.colMd4 {
  width: 25%;
  height: 100%;
  overflow: hidden;
}

.indicator {
  height: 100%;
  background-color: #EEE9EE;
  position: relative;
  color: black !important;
}


.arrowRight {
  width: 0;
  height: 0;
  border-top: 30px solid #F9F3FA;
  border-bottom: 30px solid #F9F3FA;
  border-left: 25px solid #EEE9EE;
  position: absolute;
  top: 50%;
  /* Center the arrow vertically */
  right: 0;
  /* Align to the right edge */
  transform: translateY(-50%);
  /* Adjust for perfect vertical centering */
  z-index: 1;
  overflow: hidden;

}

.arrowRightStart {
  width: 0;
  height: 0;
  border-top: 30px solid transparent;
  border-bottom: 30px solid transparent;
  border-left: 25px solid #F9F3FA;
  position: absolute;
  top: 50%;
  /* Center the arrow vertically */
  left: 0;
  /* Align to the right edge */
  transform: translateY(-50%);
  /* Adjust for perfect vertical centering */
  z-index: 1;
  overflow: hidden;
}

.bodyArrow {
  background-color: #004D99 !important;
}

.borderArrow {
  border-left-color: #004D99 !important;
}


.indicator:hover {
  background-color: #93a5b7;
  cursor: pointer;
  color: rgb(209, 23, 23) !important;

  .arrowRight {
    border-left: 25px solid #93a5b7;
  }
}

.indicatorActive {
  background-color: #93a5b7 !important;
  color: #fff !important;

}

.arrowRightActive {
  border-left: 25px solid #93a5b7 !important;
}

.documetContainer {
  background-color: #FEF7FF;
  /* border: 2px solid #747171c2; */
  /* padding: 0px 10px; */
  border: 1px solid rgb(216, 214, 214);
  border-radius: 5px;
  padding-bottom: "1rem";
  padding-top: "1rem";
}




/* comments */
.headerText {
  margin: 0px;
  font-weight: 300;
  font-size: 1.875rem;
  line-height: 30px;
}

.commentBox {
  border: 1px solid #250225;
  border-radius: 20px;
  padding: 20px 20px 0px;
  margin: 20px 0px;
  background-color: #FEF7FF;
  transform: scale(1);
  transition: all 0.2s ease-in-out;
  /* Add transition */
}

.activeCommentBox {
  border: 2px solid #870eaf;
  background-color: #FEF7FF;
  transform: scale(1.02);
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.versionList {
  position: absolute;
  top: 100%;
  left: 100%;
  transform: translateX(-50%);
  min-width: 150px;
  background-color: #FEF7FF;
  border: 0.5px solid #9c9c9ca4;
  cursor: pointer;
}

.versionListVersion {
  border-top: none;
  background-color: #FEF7FF;
  border: 0.5px solid #9c9c9ca4;
  padding: 7px;
  height: 100%;
  color: #33383d;
  line-height: 20px;
  font-size: 1.25rem;
  font-weight: 600;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  /* fon */
}

.versionListVersion:hover {
  background-color: #F4EDFB;
  cursor: pointer;
}



.statusButton {
  border-color: #004D99;
  color: #004D99;
  border-radius: 5px;
  padding: 6px 12px;
  font-size: 1rem;
  font-weight: 500;
  margin-right: 8px;
}

.statusButton:hover {
  background-color: #004D99;
  color: #fff;
}

/* message icon style */
.messageIcon {
  font-size: 1.5rem;
  /* margin-right: 555px; */
  filter: drop-shadow(0 2px 4px rgba(0, 0, 0, 0.25));
}

/* Comment Rplay */
.commentRplayInput {
  margin-bottom: 20px;
  border: 1px solid #250225;
  border-radius: 20px;
  padding: 20px 20px 0px;
  background-color: #FEF7FF;
  transform: scale(1);
  transition: all 0.2s ease-in-out;
  /* Add transition */
}

/*  add marketing compliance */
/* Danger Gradient Button */
.btnDanger {
  color: #fff !important;
  background: linear-gradient(90deg, #ff0033, #c4001a) !important;

  transition: all 0.3s ease-in-out;
}

/* Danger Hover */
.btnDanger:hover {
  filter: brightness(1.1);
  box-shadow: 0 0 10px rgba(255, 0, 51, 0.5);
}

/* tabs */
.tabButton {
  background: transparent;
  border: none;
  padding: 10px 15px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.tabButton:hover {
  background-color: #f0f0f0;
}

/* taps */


.active {
  /* Gradient text effect */
  background: linear-gradient(90deg, #d700ff, #9c00ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  /* Gradient border effect */
  border-bottom: 3px solid;
  border-image: linear-gradient(90deg, #d700ff, #9c00ff) 1;
}

/* file details */
.keys {
  color: #353434;
}


table .bgTransparent tr td {
  background-color: transparent !important;
}