.active {
  /* Gradient text effect */
  background: linear-gradient(90deg, #d700ff, #9c00ff);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;

  /* Gradient border effect */
  border-bottom: 3px solid;
  border-image: linear-gradient(90deg, #d700ff, #9c00ff) 1;
}

.field {
  background-color: #FEF7FF;
  padding: 10px 5px;
  border: 0.5px solid transparent;
  border-radius: 5px;
  margin: 0px 0px 10px 0px;
  font-size: 1rem;
  /* box-shadow:  0px 0px 7px rgba(0, 0, 0, 0.1); */
}

.trash {
  cursor: pointer;
}

.trash:hover {
  color: red;
}

.pen {
  cursor: pointer;
}

.pen:hover {
  color: green;
}

/* CSS for the disabled div */
.disabledDiv {
  pointer-events: none;
  opacity: 0.5;
  /* Optional: to visually indicate disabled state */
}

.inputBorderColor {
  border-color: #DEE2E6 !important;
}
.form-control::placeholder {
  margin: 0px !important;
  padding: 0px !important;
}